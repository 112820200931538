import {getStaticPaths as GSPaths, getStaticProps as GSProps} from '../../default';
import DynamicPage from '../../default';
import {memoizedPatterns} from '@utils/api';

export default function Page(props) {
  return <DynamicPage {...props} />;
}

export async function getStaticPaths() {
  return await GSPaths('en');
}

export async function getStaticProps(context) {
  return await GSProps({
    ...context,
    locale: 'en',
    htmlLang: 'en',
    direction: 'ltr',
  });
}
